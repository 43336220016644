<template>
  <v-layout column class="share-screen">
    <v-flex v-if="screenSharingStarted" class="grow share-screen__video-wrap">
      <v-progress-circular
        indeterminate
        class="spin-loading"
        v-if="isProcessingScreenSharing || !mediaReady"
      />
      <UserVideo
        v-if="sharingScreenOn"
        :track="screenshareVideoTrack"
        ref="streamSrc"
        @ready="mediaReady = true"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import { db } from "@/firebase"
import { TwilioRoom } from "@/services/twilio.service"
import { SCREEN_CHANNEL_IDENTIFIER } from "@/store/TwilioModule"

import UserVideo from "@/components/GroupTeams/Common/User/UserVideo.vue"
import { debounce } from "lodash"

const CONSTRAINTS = {
  video: {
    frameRate: 15,
    width: { max: 1920 }
  },
  audio: false
}

export default {
  components: {
    UserVideo
  },
  data: () => ({
    twilioToken: null,
    mediaReady: false
  }),
  computed: {
    ...mapGetters("auth", ["token", "user"]),
    ...mapGetters(["gameID", "orgID"]),
    ...mapGetters("twilio", [
      "screenshareVideoTrack",
      "screenshareAudioTrack",
      "users"
    ]),
    ...mapGetters("ScreenCapture", [
      "isSharingScreen",
      "isSharingOnlyAudio",
      "isProcessingScreenSharing"
    ]),
    screenSharingStarted() {
      return this.sharingScreenOn || this.screenShareStarted
    },
    sharingScreenOn() {
      return this.screenshareVideoTrack?.mediaStreamTrack
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    screenShareRef() {
      return this.gameStatus?.shareRef
    },
    screenShareBy() {
      return this.screenShareRef?.screenShareBy
    },
    screenShareStarted() {
      return !!this.screenShareRef?.startTime
    }
  },
  created() {
    this._stream = null
    this._connection = null
    this._localScreenVideoTrack = null
  },
  beforeDestroy() {
    this.stopScreenShare()
  },
  destroyed() {
    this.setPriorities(null)
  },
  watch: {
    isSharingScreen: {
      handler(val, prevVal) {
        if (val) {
          this.shareScreen()
        } else {
          if (prevVal) this.stopScreenShare()
        }
      },
      immediate: true
    },
    screenShareBy(userID) {
      if (userID !== this.user?.id && this.isSharingScreen) {
        // another user is sharing
        this.stopScreenShare()
      }
    },
    users: {
      handler: debounce(function () {
        this.setPriorities("low")
      }, 1000),
      immediate: true
    }
  },
  methods: {
    ...mapActions("ScreenCapture", ["setIsSharingScreen"]),
    ...mapMutations("ScreenCapture", {
      updateProcessingScreenShareStatus: "UPDATE_IS_PROCESSING_SCREEN_SHARE"
    }),
    async shareScreen() {
      this.updateProcessingScreenShareStatus(true)

      try {
        this._stream = await navigator.mediaDevices.getDisplayMedia(CONSTRAINTS)

        this.twilioToken = await this.$store.dispatch(
          "twilio/GET_TOKEN",
          SCREEN_CHANNEL_IDENTIFIER
        )

        const [localScreenVideoTrack] = this._stream.getVideoTracks()

        this._localScreenVideoTrack = localScreenVideoTrack

        this.shareRef = db
          .auxiliary()
          .ref(`org/${this.orgID}/game/${this.gameID}/gameStatus`)

        const result = await this.shareRef
          .child("shareRef")
          .transaction(screenShare => {
            screenShare = screenShare ?? {}
            if (
              screenShare?.startTime &&
              Date.now() - screenShare?.startTime < 5000
            )
              return
            screenShare.startTime = Date.now()
            screenShare.screenShareBy = this.user?.id
            return screenShare
          })

        console.log("setting broadcasting in game", result)

        if (!result.committed)
          throw new Error("Something went wrong, please try again.")

        this.shareRef.onDisconnect().update({ shareRef: null })

        const hasActiveTrack = () => {
          const tracks = this._stream.getTracks()
          return tracks.some(track => track.readyState !== "ended")
        }

        if (!hasActiveTrack()) this.stopScreenShare()

        console.log("Connecting to the room...")

        this._connection = await TwilioRoom.connect({
          token: this.twilioToken,
          name: this.gameID,
          bandwidthProfile: {
            video: {
              dominantSpeakerPriority: "standard",
              mode: "presentation",
              contentPreferencesMode: "auto",
              clientTrackSwitchOffControl: "auto",
              trackSwitchOffMode: "predicted"
            }
          }
        })

        this._localScreenVideoTrack.onended = () => {
          this._connection?.localParticipant?.unpublishTrack(
            this._localScreenVideoTrack
          )

          this.stopScreenShare()

          this._localScreenVideoTrack.onended = undefined
        }

        this.localTrackPublication =
          await this._connection.localParticipant.publishTrack(
            this._localScreenVideoTrack,
            {
              priority: "high"
            }
          )

        console.log("---->>>>>", this._connection, this.localTrackPublication)
      } catch (e) {
        console.log(e.message, e)
        alert(e.message)
        this.stopScreenShare()
      }
      this.updateProcessingScreenShareStatus(false)
    },
    setPriorities(priority) {
      Object.entries(this.users).forEach(([identity, object]) => {
        if (object.videoTrack && identity !== this.user.id) {
          console.group("priority for: ", identity)
          console.log("priority before:", object.videoTrack.priority)
          object.videoTrack.setPriority(
            SCREEN_CHANNEL_IDENTIFIER === identity && priority !== null
              ? "high"
              : priority
          )
          console.log("priority after:", object.videoTrack.priority)
          console.groupEnd()
        }
      })
    },
    async stopScreenShare() {
      this.setPriorities(null)

      const tracks = this._stream?.getTracks() || []
      tracks.forEach(track => {
        track.stop()
      })

      this._connection?.disconnect()

      this.mediaReady = false
      this.setIsSharingScreen(false)
      this.updateProcessingScreenShareStatus(false)

      this._localScreenVideoTrack = this._connection = this._stream = undefined

      if (this.gameStatus.shareRef?.screenShareBy === this.user.id) {
        this.shareRef?.onDisconnect()?.cancel()

        await this.shareRef.child("shareRef").transaction(shareRef => {
          return shareRef ? {} : null
        })
      }
    }
  }
}
</script>

<style lang="scss">
.share-screen {
  overflow: hidden;
  &__stop-share {
    z-index: 333;
  }
  &__video-wrap {
    height: 100%;
    video,
    canvas {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 100%;
      -webkit-transform: unset !important;
      transform: unset !important;
      object-fit: revert;
    }
    & .spin-loading,
    .audio-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      color: $primary_accent_color;
    }
    .audio-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      canvas {
        width: 50%;
        height: 50%;
      }
    }
  }
}
</style>
