var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "share-screen", attrs: { column: "" } },
    [
      _vm.screenSharingStarted
        ? _c(
            "v-flex",
            { staticClass: "grow share-screen__video-wrap" },
            [
              _vm.isProcessingScreenSharing || !_vm.mediaReady
                ? _c("v-progress-circular", {
                    staticClass: "spin-loading",
                    attrs: { indeterminate: "" },
                  })
                : _vm._e(),
              _vm.sharingScreenOn
                ? _c("UserVideo", {
                    ref: "streamSrc",
                    attrs: { track: _vm.screenshareVideoTrack },
                    on: {
                      ready: function ($event) {
                        _vm.mediaReady = true
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }